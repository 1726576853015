<script lang="ts" setup>
    import {
        BetriebspruefungAbschliessenCell,
        BeurkundungAlsVersendetMarkierenDialog,
        BooleanCell,
        ChangeBemerkungOfBetriebspruefungCell,
        ChangeKontaktOfBetriebsbeauftragterCell,
        EditPruefungsergebnisCell,
        EmailCell,
        FachprueferFilter,
        type FachprueferFilterEvent,
        OrdnungspruefungVornehmenCell,
        RechnungAlsVersendetMarkierenDialog,
        ShowPruefungsergebnisDialog,
        TelefonnummerCell,
        TerminAmCell
    } from '@/components'
    import dayjs from 'dayjs'
    import { useForceFileDownload, useSettings } from '@/composables'
    import { type Betriebspruefung } from '@/js/model'
    import { useBetriebspruefungService } from '@/services'
    import { useAlertStore, useAuthStore, useFachprueferStore } from '@/stores'
    import {
        type FetchParams,
        formatDate,
        formatDateMonth,
        formatDateTime,
        itemsPerPageOptions,
        itemsPerPageText,
        loadingText,
        noItemsText,
        type SortItem,
        type TableHeaders
    } from '@/utils'
    import { computed, onBeforeMount, onMounted, type Ref, ref, watch } from 'vue'
    import { useRoute } from 'vue-router'
    import KontaktSonstigesCell from '@/components/KontaktSonstigesCell.vue'
    import type { BetriebspruefungFilterSettings } from '@/composables/settings'

    type HeaderItem = {
        title: string
        value: string
    }

    const alertStore = useAlertStore()
    const authStore = useAuthStore()
    const settings = useSettings()
    const route = useRoute()

    const isAbgeschlossen = ref<boolean | null>(null)
    const isTerminBestaetigt = ref<boolean | null>(null)
    const isTerminAngefragt = ref<boolean | null>(null)
    const isOrdnungspruefungVorgenommen = ref<boolean | null>(null)
    const isBeurkundungAlsVersendetMarkiert = ref<boolean | null>(null)
    const isRechnungAlsVersendetMarkiert = ref<boolean | null>(null)
    const terminAm = ref<Date[] | null>(null)
    const now = dayjs()
    const vorherigeZertifizierungGueltigBis = ref<{ month: number; year: number }[] | null>([
        { month: now.startOf('year').month(), year: now.year() },
        { month: now.endOf('year').month(), year: now.year() }
    ])
    const abgeschlossenAt = ref<Date[] | null>(null)

    const page = ref<number>(1)
    const itemsPerPage = ref<number>(25)
    const colorKontaktBetriebsbeauftragter = ref<string>('primary')
    const colorKontaktFachbetrieb = ref<string>('deep-purple-darken-4')
    const fachprueferFilter = ref<FachprueferFilterEvent>({ all: true, selection: [] })

    const selectedHeaders = ref<HeaderItem[]>([])
    const selectedHeadersItems = ref<HeaderItem[]>([
        {
            title: '#',
            value: 'displayNumber'
        },
        {
            title: 'Mitgliedsnummer',
            value: 'mitgliedsnummer'
        },
        {
            title: 'Firma',
            value: 'firma.nameOne'
        },
        {
            title: 'Straße',
            value: 'adresseFachbetrieb.strasse'
        },
        {
            title: 'PLZ',
            value: 'adresseFachbetrieb.postleitzahl'
        },
        {
            title: 'Ort',
            value: 'adresseFachbetrieb.ort'
        },
        {
            title: 'Kontakt',
            value: 'kontakt'
        },
        {
            title: 'Betriebsbeauftragter',
            value: 'betriebsbeauftragterId'
        },
        {
            title: 'Ablauf Urkunde',
            value: 'vorherigeZertifizierungGueltigBis'
        },
        {
            title: 'Prüfungstermin',
            value: 'Prüfungstermin'
        },
        {
            title: 'Prüfungsergebnis',
            value: 'pruefungsergebnis.mangel.mangel'
        },
        {
            title: 'Bemerkungen',
            value: 'bemerkung'
        },
        {
            title: 'Prüfung am',
            value: 'abgeschlossenAt'
        },
        {
            title: 'Letzte Änderung',
            value: 'updatedAt'
        }
    ])
    const headers = ref<TableHeaders>([
        {
            title: '#',
            align: 'start',
            key: 'displayNumber',
            sortable: false
        },
        { title: 'Mitglieds-nummer', align: 'start', key: 'mitgliedsnummer' },
        { title: 'Firma', align: 'start', key: 'firma.nameOne' },
        { title: 'Straße', align: 'start', key: 'adresseFachbetrieb.strasse' },
        { title: 'PLZ', align: 'start', key: 'adresseFachbetrieb.postleitzahl' },
        { title: 'Ort', align: 'start', key: 'adresseFachbetrieb.ort' },
        { title: 'Kontakt', align: 'start', key: 'kontakt', sortable: false },
        {
            title: 'Betriebsbeauftragter',
            align: 'start',
            key: 'betriebsbeauftragterId',
            sortable: false
        },
        {
            title: 'Ablauf Urkunde',
            align: 'start',
            key: 'vorherigeZertifizierungGueltigBis',
            sortable: true,
            value: (item) => `${formatDateMonth(item.vorherigeZertifizierungGueltigBis)}`
        },
        {
            title: 'Prüfungstermin',
            align: 'center',
            children: [
                {
                    title: '',
                    align: 'center',
                    key: 'terminAm',
                    sortable: true,
                    value: (item: Betriebspruefung) => `${formatDate(item.terminAm)}`,
                    headerProps: {
                        'data-test': 'th-pruefungstermin'
                    }
                },
                {
                    title: 'angefragt?',
                    align: 'center',
                    key: 'isTerminAngefragt',
                    sortable: true
                },
                {
                    title: 'bestätigt?',
                    align: 'center',
                    key: 'isTerminBestaetigt',
                    sortable: true
                }
            ]
        },
        {
            title: 'Prüfungsergebnis',
            align: 'center',
            key: 'pruefungsergebnis.mangel.mangel',
            value: (item: Betriebspruefung) => `${item.pruefungsergebnis?.mangel?.mangel}`
        },
        // {
        //     title: 'Mangel letzte Prüfung',
        //     align: 'start',
        //     key: 'mangelDerPruefung.mangelKey',
        //     value: (item) => item.mangelDerPruefung.mangel
        // },
        { title: 'Bemerkungen', align: 'start', key: 'bemerkung', sortable: false },
        {
            title: 'Prüfung am',
            align: 'start',
            key: 'abgeschlossenAt',
            value: (item) => `${formatDateTime(item.abgeschlossenAt)}`
        },
        {
            title: 'Letzte Änderung',
            align: 'start',
            key: 'updatedAt',
            value: (item) => `${formatDateTime(item.updatedAt)}`
        }
    ])

    const filteredHeaders = computed(() => {
        const isAllowedToAccessBemerkung = authStore.hasRole('Fachpruefer')
        if (!headers.value) {
            return []
        }
        return headers.value
            .filter(
                // bemerkung is only visible for Fachpruefer
                (header) => header.key !== 'bemerkung' || isAllowedToAccessBemerkung
            )
            .filter((header) => {
                return selectedHeaders.value.some(
                    (selectedHeader) =>
                        selectedHeader.value === header.key || selectedHeader.title === header.title
                )
            })
    })

    const serverItems: Ref<Betriebspruefung[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const isExportLoading = ref<boolean>(false)
    const exportFetchParams = ref<FetchParams>({})
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)
    const postleitzahl = ref<string | null>(null)
    const searchWord = ref<string | null>(null)
    const search = ref<string>('')

    const betriebspruefungService = useBetriebspruefungService()
    const fachprueferStore = useFachprueferStore()

    const isFachpruefer = computed(() => {
        return authStore.hasRole('Fachpruefer')
    })
    const isLandesstelle = computed(() => {
        return authStore.hasRole('Landesstelle')
    })
    const isUewg = computed(() => {
        return authStore.hasRole('Uewg')
    })
    const fachpruefende = computed(() => {
        return fachprueferStore.fachpruefende
    })
    const isDisplaySchulungGueltigkeit = computed<boolean>(() => {
        return authStore.hasRole('Landesstelle')
    })

    const betriebspruefungFilter = computed<BetriebspruefungFilterSettings>(() => ({
        isAbgeschlossen: isAbgeschlossen.value,
        isTerminBestaetigt: isTerminBestaetigt.value,
        isTerminAngefragt: isTerminAngefragt.value,
        isOrdnungspruefungVorgenommen: isOrdnungspruefungVorgenommen.value,
        isBeurkundungAlsVersendetMarkiert: isBeurkundungAlsVersendetMarkiert.value,
        isRechnungAlsVersendetMarkiert: isRechnungAlsVersendetMarkiert.value,
        terminAm: terminAm.value,
        vorherigeZertifizierungGueltigBis: vorherigeZertifizierungGueltigBis.value,
        abgeschlossenAt: abgeschlossenAt.value,
        postleitzahl: postleitzahl.value,
        searchWord: searchWord.value
    }))

    const onItemChanged = (newItem: Betriebspruefung) => {
        const index = serverItems.value.findIndex((item) => item['@id'] === newItem['@id'])

        if (index !== -1) {
            serverItems.value.splice(index, 1, newItem)
        } else {
            const message = 'Beim Ändern der Betriebsprüfung ist etwas schiefgelaufen.'
            alertStore.error(message)
            throw new Error(message)
        }
    }

    const onBetriebsbeauftragterChanged = () => {
        refreshTable()
    }

    const getFileName = () => {
        let title = `betriebsprüfungen_export.csv`

        if (null !== isAbgeschlossen.value) {
            title = `abgeschlossen_${isAbgeschlossen.value ? 'ja' : 'nein'}_${title}`
        }
        if (null !== isTerminBestaetigt.value) {
            title = `terminiert_${isTerminBestaetigt.value ? 'ja' : 'nein'}_${title}`
        }
        if (null !== isTerminAngefragt.value) {
            title = `angefragt_${isTerminAngefragt.value ? 'ja' : 'nein'}_${title}`
        }
        if (null !== isOrdnungspruefungVorgenommen.value) {
            title = `Ordnungsprüfung_${isOrdnungspruefungVorgenommen.value ? 'vorgenommen' : 'offen'}_${title}`
        }
        if (null !== isBeurkundungAlsVersendetMarkiert.value) {
            title = `Beurkundung_${isBeurkundungAlsVersendetMarkiert.value ? 'versendet' : 'nicht_versendet'}_${title}`
        }
        if (null !== isRechnungAlsVersendetMarkiert.value) {
            title = `Rechnung_${isRechnungAlsVersendetMarkiert.value ? 'versendet' : 'nicht_versendet'}_${title}`
        }
        if (postleitzahl.value) {
            title = `Postleitzahl_${postleitzahl.value}_${title}`
        }
        if (searchWord.value) {
            title = `Suchwort_${searchWord.value}_${title}`
        }
        if (terminAm.value) {
            title = `Termin_am_${formatDate(terminAm.value[0])}-${formatDate(terminAm.value[1])}_${title}`
        }
        if (abgeschlossenAt.value) {
            title = `abgeschlossen_am_${formatDate(abgeschlossenAt.value[0])}-${formatDate(abgeschlossenAt.value[1])}_${title}`
        }
        if (vorherigeZertifizierungGueltigBis.value) {
            const von = dayjs()
                .month(vorherigeZertifizierungGueltigBis.value[0].month)
                .year(vorherigeZertifizierungGueltigBis.value[0].year)
                .toDate()
            const bis = dayjs()
                .month(vorherigeZertifizierungGueltigBis.value[1].month)
                .year(vorherigeZertifizierungGueltigBis.value[1].year)
                .toDate()
            title = `Ablauf_Urkunde_${formatDateMonth(von)}-${formatDateMonth(bis)}_${title}`
        }

        return title
    }

    const exportBetriebspruefungen = async () => {
        isExportLoading.value = true
        exportFetchParams.value.itemsPerPage = 100000
        const response = await betriebspruefungService.exportBetriebspruefungen(
            exportFetchParams.value
        )
        useForceFileDownload(response, getFileName())
        isExportLoading.value = false
    }

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage,
            isAbgeschlossen:
                false === isOrdnungspruefungVorgenommen.value ||
                false === isRechnungAlsVersendetMarkiert.value
                    ? true
                    : isAbgeschlossen.value,
            isTerminBestaetigt: isTerminBestaetigt.value,
            isTerminAngefragt: isTerminAngefragt.value,
            isOrdnungspruefungVorgenommen:
                false === isBeurkundungAlsVersendetMarkiert.value
                    ? true
                    : isOrdnungspruefungVorgenommen.value,
            isRechnungAlsVersendetMarkiert: isRechnungAlsVersendetMarkiert.value,
            isBeurkundungAlsVersendetMarkiert: isBeurkundungAlsVersendetMarkiert.value,
            postleitzahl: postleitzahl.value,
            search: searchWord.value,
            ...getFachpruefendeFilter()
        }

        if (terminAm.value) {
            data['terminAm[after]'] = dayjs(terminAm.value[0]).startOf('day').toISOString()
            data['terminAm[before]'] = dayjs(terminAm.value[1]).endOf('day').toISOString()
        }
        if (vorherigeZertifizierungGueltigBis.value) {
            data['vorherigeZertifizierungGueltigBis[after]'] = dayjs()
                .year(vorherigeZertifizierungGueltigBis.value[0].year)
                .month(vorherigeZertifizierungGueltigBis.value[0].month)
                .startOf('month')
                .toISOString()
            data['vorherigeZertifizierungGueltigBis[before]'] = dayjs()
                .year(vorherigeZertifizierungGueltigBis.value[1].year)
                .month(vorherigeZertifizierungGueltigBis.value[1].month)
                .endOf('month')
                .toISOString()
        }

        if (abgeschlossenAt.value) {
            data['abgeschlossenAt[after]'] = dayjs(abgeschlossenAt.value[0])
                .startOf('day')
                .toISOString()
            data['abgeschlossenAt[before]'] = dayjs(abgeschlossenAt.value[1])
                .endOf('day')
                .toISOString()
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }

        exportFetchParams.value = data

        try {
            const result = await betriebspruefungService.getAllBetriebspruefungen(data)
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            loading.value = false
        }
    }

    function refreshTable() {
        search.value = String(Date.now())
    }

    watch(
        [
            postleitzahl,
            isAbgeschlossen,
            isTerminBestaetigt,
            isTerminAngefragt,
            isOrdnungspruefungVorgenommen,
            isBeurkundungAlsVersendetMarkiert,
            isRechnungAlsVersendetMarkiert,
            terminAm,
            vorherigeZertifizierungGueltigBis,
            abgeschlossenAt,
            searchWord
        ],
        () => {
            refreshTable()
            settings.betriebspruefungFilter.store(betriebspruefungFilter.value)
        },
        { deep: true }
    )

    onBeforeMount(async () => {
        await Promise.all([fachprueferStore.fetchFachpruefende()])
        selectedHeaders.value = [...selectedHeadersItems.value]
    })

    onMounted(async () => {
        selectedHeaders.value = [...selectedHeadersItems.value]
        if (route.query.mitgliedsnummer) {
            searchWord.value = (route.query.mitgliedsnummer as string) || ''
        } else {
            const betriebspruefungFilter = settings.betriebspruefungFilter.load()
            if (betriebspruefungFilter) {
                const mappings: Record<keyof BetriebspruefungFilterSettings, Ref<any>> = {
                    isAbgeschlossen,
                    terminAm,
                    vorherigeZertifizierungGueltigBis,
                    abgeschlossenAt,
                    isTerminBestaetigt,
                    isTerminAngefragt,
                    isOrdnungspruefungVorgenommen,
                    isBeurkundungAlsVersendetMarkiert,
                    isRechnungAlsVersendetMarkiert,
                    postleitzahl,
                    searchWord
                }

                for (const key of Object.keys(
                    mappings
                ) as (keyof BetriebspruefungFilterSettings)[]) {
                    const ref = mappings[key]
                    if (betriebspruefungFilter[key] !== undefined) {
                        ref.value = betriebspruefungFilter[key] ?? ref.value
                    }
                }
            }
        }
    })

    const getFachpruefendeFilter = () => {
        if (fachprueferFilter.value.all) {
            return { fachprueferId: null }
        } else {
            return { fachprueferId: fachprueferFilter.value.selection }
        }
    }
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="4" xl="2">
            <v-text-field
                v-model="searchWord"
                density="compact"
                placeholder="Suchwort eingeben..."
                append-inner-icon="mdi-magnify"
                data-test="search-word"
                hide-details
                clearable
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" xl="2">
            <v-text-field
                v-model="postleitzahl"
                density="compact"
                placeholder="Postleitzahl (z. B. 51438, 51403-51636, 52)"
                append-inner-icon="mdi-magnify"
                title="Findet einzelne Postleitzahlen (51438), Postleitzahlbereiche (51403-51636) und alle Postleitzahlen in einer Region (52). Mehrere Angaben werden durch Komma getrennt. Beispiel: Alle Postleitzahlen in Kassel = 341-342, 34355"
                data-test="search-postleitzahl"
                hide-details
                clearable
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-btn-toggle v-model="isAbgeschlossen" color="primary" group density="compact">
                <v-btn :value="true">Abgeschlossen</v-btn>
                <v-btn :value="false">Offen</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <VLabel text="Prüfungstermin" class="text-caption" /><br />
            <v-btn-toggle v-model="isTerminAngefragt" color="primary" group density="compact" slim>
                <v-btn :value="true">angefragt</v-btn>
                <v-btn :value="false">nicht angefragt</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col v-if="isUewg" cols="12" sm="6" md="4" lg="3" xl="2">
            <VLabel text="Beurkundung versendet" class="text-caption" /><br />
            <v-btn-toggle
                v-model="isBeurkundungAlsVersendetMarkiert"
                color="primary"
                group
                density="compact"
                slim
            >
                <v-btn :value="false">offen</v-btn>
                <v-btn :value="true">versendet</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col v-if="isUewg" cols="12" sm="6" md="4" lg="3" xl="2">
            <VLabel text="Rechnung versendet" class="text-caption" /><br />
            <v-btn-toggle
                v-model="isRechnungAlsVersendetMarkiert"
                color="primary"
                group
                density="compact"
                slim
            >
                <v-btn :value="false">nein</v-btn>
                <v-btn :value="true">versendet</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <VLabel text="Prüfungstermin" class="text-caption" /><br />
            <v-btn-toggle v-model="isTerminBestaetigt" color="primary" group density="compact" slim>
                <v-btn :value="true">bestätigt</v-btn>
                <v-btn :value="false">nicht bestätigt</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <VLabel text="Prüfungstermin am" class="text-caption" />
            <VueDatePicker
                v-model="terminAm"
                :week-numbers="{ type: 'iso' }"
                placeholder="Zeitraum wählen..."
                :multi-calendars="{ solo: false, static: true, count: 2 }"
                clearable
                range
                data-test="termin-am-filter"
                text-input
                auto-apply
                locale="de"
                :enable-time-picker="false"
                format="dd.LL.y"
                cancel-text="abbrechen"
                select-text="auswählen"
                :teleport="true"
                six-weeks="center"
                :action-row="{ showPreview: true }"
            />
        </v-col>
        <v-col v-if="isUewg || isLandesstelle" cols="12" sm="6" md="4" lg="3" xl="2">
            <VLabel text="Ordnungsprüfung" class="text-caption" /><br />
            <v-btn-toggle
                v-model="isOrdnungspruefungVorgenommen"
                color="primary"
                group
                density="compact"
                slim
            >
                <v-btn :value="false">offen</v-btn>
                <v-btn :value="true">vorgenommen</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col v-if="!isFachpruefer && fachpruefende.length > 1" cols="12" md="3">
            <FachprueferFilter
                @selectionChanged="
                    async (newFilter) => {
                        fachprueferFilter = newFilter
                        await loadItems({ ...tableOptions })
                    }
                "
            />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <VLabel text="Ablauf Urkunde am" class="text-caption" />
            <VueDatePicker
                v-model="vorherigeZertifizierungGueltigBis"
                :week-numbers="{ type: 'iso' }"
                placeholder="Zeitraum wählen..."
                :multi-calendars="{ solo: false, static: true, count: 2 }"
                clearable
                month-picker
                range
                data-test="ablauf-urkunde-filter"
                text-input
                auto-apply
                locale="de"
                :enable-time-picker="false"
                format="LL/yy"
                cancel-text="abbrechen"
                select-text="auswählen"
                :teleport="true"
                six-weeks="center"
                :action-row="{ showPreview: true }"
            />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <VLabel text="Abgeschlossen am" class="text-caption" />
            <VueDatePicker
                v-model="abgeschlossenAt"
                :week-numbers="{ type: 'iso' }"
                placeholder="Zeitraum wählen..."
                :multi-calendars="{ solo: false, static: true, count: 2 }"
                clearable
                range
                data-test="abgeschlossen-am-filter"
                text-input
                auto-apply
                locale="de"
                :enable-time-picker="false"
                format="dd.LL.y"
                cancel-text="abbrechen"
                select-text="auswählen"
                :teleport="true"
                six-weeks="center"
                :action-row="{ showPreview: true }"
            />
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-select
                v-model="selectedHeaders"
                :items="selectedHeadersItems"
                label="Spalten wählen"
                multiple
                outlined
                dense
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                        <span>{{ item.title }}</span>
                    </v-chip>
                    <span v-if="index === 2" class="grey--text caption"
                        >(+{{ selectedHeaders?.length - 2 }} weitere)</span
                    >
                </template>
            </v-select>
        </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :headers="filteredHeaders"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        :search="search"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        @update:options="loadItems"
        density="compact"
        fixed-header
        fixed-footer
        hover
        height="68vh"
    >
        <template v-slot:item.displayNumber="{ index }">
            {{ index + 1 + (page - 1) * itemsPerPage }}
        </template>
        <template v-slot:item.firma.nameOne="{ item }">
            {{ item.firma.name }}
        </template>
        <template v-slot:item.pruefungsergebnis.mangel.mangel="{ item }">
            <EditPruefungsergebnisCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
            <ShowPruefungsergebnisDialog
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
            <BetriebspruefungAbschliessenCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
            <RechnungAlsVersendetMarkierenDialog
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
            <BeurkundungAlsVersendetMarkierenDialog
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
            <OrdnungspruefungVornehmenCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
        </template>
        <template v-slot:item.bemerkung="{ item }">
            <ChangeBemerkungOfBetriebspruefungCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
        </template>
        <template v-slot:item.terminAm="{ item }">
            <TerminAmCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
        </template>
        <template v-slot:item.isTerminAngefragt="{ item }">
            <BooleanCell
                v-if="!item.isAbgeschlossen"
                :is-enabled="item.isTerminAngefragt"
                data-test="termin-angefragt-indicator"
            />
        </template>
        <template v-slot:item.isTerminBestaetigt="{ item }">
            <BooleanCell
                v-if="!item.isAbgeschlossen"
                :is-enabled="item.isTerminBestaetigt"
                data-test="termin-bestaetigt-indicator"
            />
        </template>
        <template v-slot:item.betriebsbeauftragterId="{ item }">
            <ChangeKontaktOfBetriebsbeauftragterCell
                v-if="item.betriebsbeauftragterId"
                :betriebsbeauftragterId="item.betriebsbeauftragterId"
                :betriebspruefung="item"
                :key="item.betriebsbeauftragterId"
                :display-schulung-gueltigkeit="isDisplaySchulungGueltigkeit"
                @betriebsbeauftragter-changed="onBetriebsbeauftragterChanged"
            />
        </template>
        <template v-slot:item.kontakt="{ item }">
            <template v-for="(email, key) in item?.kontaktFachbetrieb?.email" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip :color="colorKontaktFachbetrieb" variant="tonal" size="x-small"
                        >FB
                    </v-chip>
                    <EmailCell :email="email" :color="colorKontaktFachbetrieb" />
                </div>
            </template>
            <template v-for="(telefonnummer, key) in item?.kontaktFachbetrieb?.telefon" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip :color="colorKontaktFachbetrieb" variant="tonal" size="x-small"
                        >FB
                    </v-chip>
                    <TelefonnummerCell
                        :telefonnummer="telefonnummer"
                        :color="colorKontaktFachbetrieb"
                    />
                </div>
            </template>
            <template v-for="(mobil, key) in item?.kontaktFachbetrieb?.mobil" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip :color="colorKontaktFachbetrieb" variant="tonal" size="x-small"
                        >FB
                    </v-chip>
                    <TelefonnummerCell :telefonnummer="mobil" :color="colorKontaktFachbetrieb" />
                </div>
            </template>
            <template v-for="(email, key) in item?.kontaktBetriebsbeauftragter?.email" :key="key">
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip :color="colorKontaktBetriebsbeauftragter" variant="tonal" size="x-small"
                        >B
                    </v-chip>
                    <EmailCell :email="email" :color="colorKontaktBetriebsbeauftragter" />
                </div>
            </template>
            <template
                v-for="(telefonnummer, key) in item?.kontaktBetriebsbeauftragter?.telefon"
                :key="key"
            >
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip :color="colorKontaktBetriebsbeauftragter" variant="tonal" size="x-small"
                        >B
                    </v-chip>
                    <TelefonnummerCell
                        :telefonnummer="telefonnummer"
                        :color="colorKontaktBetriebsbeauftragter"
                    />
                </div>
            </template>
            <template v-for="(mobil, key) in item?.kontaktBetriebsbeauftragter?.mobil" :key="key">
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip :color="colorKontaktBetriebsbeauftragter" variant="tonal" size="x-small"
                        >B
                    </v-chip>
                    <TelefonnummerCell
                        :telefonnummer="mobil"
                        :color="colorKontaktBetriebsbeauftragter"
                    />
                </div>
            </template>
            <template
                v-for="(sonstiges, key) in item?.kontaktBetriebsbeauftragter?.sonstiges"
                :key="key"
            >
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip :color="colorKontaktBetriebsbeauftragter" variant="tonal" size="x-small"
                        >B
                    </v-chip>
                    <KontaktSonstigesCell
                        :sonstiges="sonstiges"
                        :color="colorKontaktBetriebsbeauftragter"
                    />
                </div>
            </template>
        </template>
    </v-data-table-server>
    <v-btn
        small
        color="primary"
        block
        :disabled="loading || isExportLoading || totalItems === 0"
        @click="exportBetriebspruefungen"
        >{{ totalItems }} Betriebsprüfung{{ totalItems === 1 ? '' : 'en' }} als .csv-Datei
        exportieren
        <v-icon v-if="!isExportLoading" icon="mdi-download" />
        <v-progress-circular v-else indeterminate size="x-small" class="ml-2" />
    </v-btn>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
