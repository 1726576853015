<script setup lang="ts">
    import { type Betriebspruefung, type Schulung, type Schulungen } from '@/js/model'

    import { computed } from 'vue'
    import dayjs from 'dayjs'
    import { formatDate } from '@/utils'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
        schulungen: Schulungen
        displayGueltigkeit: boolean
    }>()

    enum SchulungsStatus {
        Teilgenommen,
        Bestanden,
        NichtBestanden,
        KeineSchulung
    }

    const schulungsStatus = computed<SchulungsStatus>(() => {
        const terminAm = dayjs(props.betriebspruefung.terminAm)
        const twoYearsAgo = terminAm.subtract(2, 'year')

        const latestSchulungen = props.schulungen.schulungen.filter((schulung: Schulung) => {
            const schulungAt = dayjs(schulung.schulungAt)
            return schulungAt.isAfter(twoYearsAgo) && schulungAt.isBefore(terminAm)
        })

        if (latestSchulungen.length === 0) {
            return SchulungsStatus.KeineSchulung
        }

        if (
            latestSchulungen.some((schulung: Schulung) => {
                return schulung.isWiederholungsschulung
            })
        ) {
            return SchulungsStatus.Teilgenommen
        }

        if (
            latestSchulungen.some((schulung: Schulung) => {
                return schulung.isBestanden
            })
        ) {
            return SchulungsStatus.Bestanden
        }

        return SchulungsStatus.NichtBestanden
    })

    const letzteErfolgreicheSchulung = computed<Schulung | undefined>(() => {
        return props.schulungen.schulungen.find((schulung: Schulung) => {
            return schulung.isBestanden || schulung.isWiederholungsschulung
        })
    })

    const hasGueltigeSchulung = computed<boolean>(() => {
        return (
            schulungsStatus.value !== SchulungsStatus.KeineSchulung &&
            schulungsStatus.value !== SchulungsStatus.NichtBestanden
        )
    })
</script>

<template>
    <span class="text-no-wrap chip-spacing">
        <v-chip
            :color="hasGueltigeSchulung ? 'success' : 'error'"
            :prepend-icon="hasGueltigeSchulung ? 'mdi-check-circle-outline' : 'mdi-close'"
        >
            <template v-if="!letzteErfolgreicheSchulung">
                <span>keine erfolgreiche Schulung</span>
            </template>
            <template v-else-if="displayGueltigkeit">
                <span
                    v-if="
                        letzteErfolgreicheSchulung &&
                        schulungsStatus === SchulungsStatus.KeineSchulung
                    "
                >
                    Abgelaufene Sch.
                </span>
                <span v-else-if="schulungsStatus === SchulungsStatus.Teilgenommen"> WS </span>
                <span v-else>FBS</span>
                &nbsp;gültig bis&nbsp;
                <span v-if="letzteErfolgreicheSchulung">
                    {{
                        formatDate(
                            dayjs(letzteErfolgreicheSchulung.schulungAt)
                                .add(2, 'year')
                                .subtract(1, 'day')
                                .toISOString()
                        )
                    }}
                </span>
            </template>
            <template v-else>
                <span
                    v-if="
                        letzteErfolgreicheSchulung &&
                        schulungsStatus === SchulungsStatus.KeineSchulung
                    "
                >
                    Abgelaufene Sch.
                </span>
                <span v-else-if="schulungsStatus === SchulungsStatus.Teilgenommen">
                    WS teilgenommen
                </span>
                <span v-else>FBS bestanden</span>
                &nbsp;am&nbsp;
                <span v-if="letzteErfolgreicheSchulung">
                    {{ formatDate(letzteErfolgreicheSchulung.schulungAt) }}
                </span>
            </template>
        </v-chip>
    </span>
</template>

<style scoped>
    .chip-spacing {
        margin-top: 1px;
        margin-bottom: 1px;
        display: inline-block;
    }
</style>
