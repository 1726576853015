<script setup lang="ts">
    import {
        type Betriebspruefung,
        type NehmeOrdnungspruefungVorRequest,
        type Zertifizierung
    } from '@/js/model'
    import { useBetriebspruefungService, useZertifizierungService } from '@/services'
    import { formatDate } from '@/utils'
    import { computed, onMounted, ref } from 'vue'
    import DokumentPreviewCell from '@/components/DokumentPreviewCell.vue'
    import { useAlertStore, useAuthStore, useZertifizierungStore } from '@/stores'
    import { UploadErgebnisDerPruefungDokumentDialog } from '@/components/index'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const alertStore = useAlertStore()
    const authStore = useAuthStore()
    const zertifizierungStore = useZertifizierungStore()
    const isLandesstelle = computed(() => {
        return authStore.hasRole('Landesstelle')
    })

    const service = useBetriebspruefungService()
    const zertifizierungService = useZertifizierungService()

    const dialog = ref<boolean>(false)
    const zertifizierung = ref<Zertifizierung | null>(null)
    const isSubmitDisabled = computed(() => {
        return (
            props.betriebspruefung.pruefungsergebnis.mangel.isUnknown ||
            props.betriebspruefung?.dokumentIds?.length === 0
        )
    })

    onMounted(async () => {
        zertifizierung.value = await zertifizierungService.getZertifizierungById(
            props.betriebspruefung.zertifizierungId
        )
    })

    const onItemChanged = (result: Betriebspruefung) => {
        emit('itemChanged', result)
    }

    const handleSubmit = async () => {
        const request: NehmeOrdnungspruefungVorRequest = {
            betriebspruefung: props.betriebspruefung['@id'],
            zertifizierung: `api/zertifizierungen/${props.betriebspruefung.zertifizierungId}`
        }

        const result = await zertifizierungService.nehmeOrdnungspruefungVor(request)

        if (result) {
            zertifizierung.value = result
            const newBetriebspruefung = await service.getBetriebspruefungById(
                result.betriebspruefungId
            )

            emit('itemChanged', newBetriebspruefung)
            zertifizierungStore.addOrReplaceZertifizierung(result)
            alertStore.success(
                `Ordnungsprüfung erfolgreich vorgenommen. Eine E-Mail wurde an die ÜWG gesendet.`
            )
            dialog.value = false
        }
    }

    const handleAbort = async () => {
        dialog.value = false
    }
</script>

<template>
    <v-dialog
        v-if="
            betriebspruefung.isAbgeschlossen &&
            isLandesstelle &&
            zertifizierung &&
            !zertifizierung.isOrdnungspruefungVorgenommen
        "
        v-model="dialog"
        min-width="400"
        max-width="2000"
    >
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                append-icon="mdi-receipt-send"
                variant="elevated"
                color="primary"
                size="small"
                class="my-1"
                data-test="btn-ordnungspruefung-vornehmen"
                >Ordnungsprüfung vornehmen</v-btn
            >
        </template>
        <v-card prepend-icon="mdi-receipt-send" title="Ordnungsprüfung vornehmen und an ÜWG senden">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="12" sm="12">
                        <span><b>Prüfungsdatum:</b></span>
                        {{ formatDate(betriebspruefung.terminAm) }}
                    </v-col>
                    <v-divider />
                    <v-col cols="12" md="12" sm="12">
                        <span><b>Prüfungsergebnis:</b></span>
                        {{ betriebspruefung.pruefungsergebnis.mangel.mangel }}
                    </v-col>
                    <v-divider />
                    <v-col cols="12" md="12" sm="12"> </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <div><b>Bemerkung:</b></div>
                        <span
                            v-html="betriebspruefung.pruefungsergebnis.bemerkung ?? '-'"
                            class="nl2br"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="betriebspruefung.dokumentIds.length" dense max-height="50vh">
                    <v-col>
                        <div><b>Dokumente:</b></div>
                        <v-sheet>
                            <v-row>
                                <v-col
                                    v-for="dokumentId in betriebspruefung.dokumentIds"
                                    :key="dokumentId"
                                    class="d-flex child-flex"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="4"
                                    xl="3"
                                    xxl="2"
                                >
                                    <DokumentPreviewCell
                                        :dokument-id="dokumentId"
                                        :key="dokumentId"
                                        class="grid"
                                    />
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-card-text>
            <UploadErgebnisDerPruefungDokumentDialog
                :betriebspruefung="betriebspruefung"
                @item-changed="onItemChanged"
            />

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn text="Abbrechen" variant="plain" @click="handleAbort"></v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="primary"
                    text="Ordnungsprüfung abschließen und an ÜWG senden"
                    variant="tonal"
                    :disabled="isSubmitDisabled"
                    @click="handleSubmit"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
